import React from 'react'
import { HiShoppingCart } from 'react-icons/hi'
import { MdOutlineFavoriteBorder } from 'react-icons/md'
import { FaRegUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useCart } from '../../hooks/useCart'
import styles from './Header.module.scss'

export default function Header({ onClickCart }) {
  const { totalPrice, favorites } = useCart()

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <Link className={styles.link} to={'/'} exact="true">
          <img
            className={styles.logo}
            width={40}
            height={40}
            src="img/logo-big.svg"
            alt="Logo"
          />
          <div>
            <p className="opacity-8">
              Настоящие роллы | Доставка суши в Екатеринбурге
            </p>
          </div>
        </Link>
      </div>

      <div>
        <ul>
          <li onClick={onClickCart}>
            <HiShoppingCart
              className={styles.cart}
              style={totalPrice && { color: '#F5E25A' }}
            />
            <span className={styles.price}>{totalPrice} руб.</span>
          </li>
          <li>
            <Link className={styles.link} to={'/favorites'}>
              <MdOutlineFavoriteBorder
                className={styles.favorites}
                style={favorites.length > 0 && { color: 'red' }}
              />
            </Link>
          </li>
          <li>
            <Link className={styles.link} to={'/orders'}>
              <FaRegUser className={styles.user} />
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}
